<template>
  <base-layout-main>
    <template v-slot:base-body>
      <section class="py-12 px-4 flex-grow">
        <div class="flex flex-wrap items-center max-w-5xl mx-auto">
          <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">
            <img
              class="lg:max-w-sm mx-auto"
              src="@/assets/images/undraw_conference_speaker_6nt7.svg"
              alt=""
              data-config-id="col1-image"
            />
          </div>
          <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">
            <h3
              class="text-4xl mb-2 font-semibold font-heading text-purple-800"
            >
              We are Toasty!
            </h3>
            <p class="mb-4 text-gray-800 leading-relaxed">
              {{ caps["/"]["p1"] }}
            </p>
            <p class="mb-4 text-gray-800 leading-relaxed">
              {{ caps["/"]["p2"] }}
            </p>
          </div>
          <div class="lg:w-1/3 px-8 mb-8 lg:mb-0 lg:order-1">
            <img
              class="lg:max-w-sm mx-auto"
              src="@/assets/images/undraw_candidate_ubwv.svg"
              alt=""
            />
          </div>
          <div class="lg:w-2/3 px-8">
            <h3
              class="text-4xl mb-2 font-semibold font-heading text-purple-800"
            >
              The gift of feedback through apps
            </h3>
            <p class="mb-4 text-gray-800 leading-relaxed">
              {{ caps["/"]["p3"] }}
            </p>
            <p class="mb-4 text-gray-800 leading-relaxed">
              {{ caps["/"]["p4"] }}
            </p>
            <p class="mb-4 text-gray-800 leading-relaxed">
              {{ caps["/"]["p5"] }}
            </p>
          </div>
        </div>
      </section>
    </template>
  </base-layout-main>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseLayoutMain from "@/layouts/BaseLayoutMain.vue";

export default defineComponent({
  name: "Home",
  components: {
    BaseLayoutMain,
  },

  setup() {
    const store = useStore();
    const caps = store.state.Captions.captions;
    return {
      caps,
    };
  },
});
</script>

<style scoped>
section {
  font-family: "Baloo 2", cursive;
}
</style>
